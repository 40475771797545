import React from 'react';
import PropTypes from 'prop-types';
import VacancyCard from '../vacancy-card/vacancy-card';
import { mergeClasses } from '../../../libs/helpers';
import './vacancies.scss';

const items = [
  {
    text: 'Грумер',
    href: '/',
  },
  {
    text: 'Администратор',
    href: '/',
  },
  {
    text: 'Уборщица',
    href: '/',
  },
  {
    text: 'Ветеренар',
    href: '/',
  },
];

const ROOT_CLASS = 'vacancies';
const LIST_CLASS = 'vacancies__list';
const ITEM_CLASS = 'vacancies__item';

const Vacancies = (props) => {
  const { className, listClass, itemClass } = props;
  const rootClass = mergeClasses(ROOT_CLASS, className);
  const listClassName = mergeClasses(LIST_CLASS, listClass);
  const itemClassName = mergeClasses(ITEM_CLASS, itemClass);

  return (
    <div className={rootClass}>
      <ul className={listClassName}>
        {items.map((item, key) => (
          <li className={itemClassName} key={key}>
            <VacancyCard text={item.text} href={item.href} />
          </li>
        ))}
      </ul>
    </div>
  );
};

Vacancies.propTypes = {
  className: PropTypes.string,
  listClass: PropTypes.string,
  itemClass: PropTypes.string,
};

Vacancies.defaultProps = {
  className: '',
  listClass: '',
  itemClass: '',
};

export default Vacancies;
