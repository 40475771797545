import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';
import './vacancy-card.scss';

const VacancyCard = (props) => {
  const { className, href, text } = props;
  const ROOT_CLASS = 'vacancy-card';
  const INNER_CLASS = 'vacancy-card__inner';
  const TEXT_CLASS = 'vacancy-card__text';

  const rootClass = mergeClasses(ROOT_CLASS, className);

  return (
    <a href={href} className={rootClass}>
      <span className={INNER_CLASS}>
        <span className={TEXT_CLASS}>{text}</span>
      </span>
    </a>
  );
};

VacancyCard.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

VacancyCard.defaultProps = {
  className: '',
};

export default VacancyCard;
