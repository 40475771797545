import React from 'react';
import StandardPage from '../components/page/page-standard';
import Vacancies from '../components/vacancies/vacancies';
import '../components/vacancies-page/vacancies-page.scss';

const pageName = 'vacancies-page';
const VACANCIES_CLASS = 'vacancies-page__vacancies';
const VACANCY_CLASS = 'vacancies-page__vacancy';

const VacanciesPage = () => (
  <StandardPage pageName={pageName} title="Вакансии">
    <Vacancies listClass={VACANCIES_CLASS} itemClass={VACANCY_CLASS} />
  </StandardPage>
);

export default VacanciesPage;
